.about-me-div {
	margin-top: 30%;
	display: flex;
	flex-direction: column;
	width: 60%;
	min-width: 300px;
	overflow: auto;
}

.about-me-div > p {
	margin: 0;
	max-height: 100%;
	color: var(--sandy-brown);
	text-align: justify;
}

.about-me-div > h3 {
	color: var(--sandy-brown);
}
