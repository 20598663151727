.UnlockScreen-div {
	height: 100%;
	display: flex;
	padding-top: 20px;
	width: 100%;
	justify-content: flex-end;
}

.UnlockScreen-div-body {
	display: flex;
	flex-direction: column;
	width: 70%;
	min-width: 550px;
	background-color: var(--eerie-black-30);
	border-radius: 5px;
	padding: 10px;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.UnlockScreen-div-body > * {
	margin: 20px 0 20px 0;
}

.UnlockScreen-Header {
	color: var(--sandy-brown);
	font-size: 3em;
	font-weight: 500;
}

.UnlockScreen-Text {
	margin: 0;
	color: var(--sandy-brown-60);
	font-size: 1.7em;
	font-weight: 300;
	margin-bottom: 2em;
}

.UnlockScreen-div-body > form > input,
.UnlockScreen-div-body > form > #submit {
	height: 2.5em;
	margin-right: 0;
	text-align: center;
}

.UnlockScreen-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70%;
}

.UnlockScreen-Message {
	color: var(--sandy-brown-30);
	min-height: 1.2em;
	min-width: 1.2em;
	font-size: 1.2em;
	margin: 0;
}
