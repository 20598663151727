.exp-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
}

.exp-body {
	overflow-y: auto;
	height: fit-content;
}
