.time-line-ele {
	display: flex;
	justify-content: flex-end;
}

.time-line-deco {
	width: 2em;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.time-line-deco-top {
	border-width: 0 0.3em 0 0;
	border-color: var(--sandy-brown);
	border-style: solid;
	flex-grow: 1;
}

.time-line-deco-top-first {
	border-width: 0 0.3em 0 0;
	border-color: var(--sandy-brown);
	border-style: none;
	flex-grow: 1;
}

.time-line-deco-mid {
	width: 1em;
	height: 2em;
	border-radius: 2em 0 0 2em;
	background-color: var(--sandy-brown);
}

.time-line-deco-bot {
	border-width: 0 0.3em 0 0;
	border-color: var(--sandy-brown);
	border-style: solid;
	flex-grow: 1;
}

.time-line-deco-bot-last {
	border-width: 0 0.3em 0 0;
	border-color: var(--sandy-brown);
	border-style: dashed;
	flex-grow: 1;
}

.time-line-text {
	color: var(--sandy-brown);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: end;
	margin: 0.5em 0 0.5em 0;
	background-color: var(--eerie-black-30);
	padding: 0.5em 0.5em 1em 1em;
	border-radius: 5px;
}

.time-line-text h2 {
	font-weight: 600;
	font-size: 2.25em;
}

.time-line-text h3 {
	margin: -0.2em 0 0.2em 0;
	font-weight: 550;
	font-size: 1.25em;
}

.time-line-text ul,
.time-line-text li {
	text-align: left;
	margin: 0;
	padding: 0;
}

.time-line-text li {
	list-style: disc;
	list-style-position: inside;
}
