.contact-div {
	height: 100%;
	display: flex;
	padding-top: 20px;
	width: 100%;
	justify-content: flex-end;
}

.contact-div-body {
	display: flex;
	flex-direction: column;
	width: 70%;
}

.contact-header {
	color: var(--sandy-brown);
	font-size: 3em;
	font-weight: 500;
	margin-bottom: 20px;
}

.contact-from-div {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.contact-from-div > * {
	height: 2.5em;
}

input {
	width: 48%;
	box-sizing: border-box;
	margin-right: 2%;
	margin-bottom: 1em;
	background: transparent;
	font-size: 1em;
	border: none;
	border-bottom: 2px solid var(--sandy-brown-60);
	color: var(--sandy-brown);
}

textarea {
	width: 48%;
	box-sizing: border-box;
	margin-right: 2%;
	margin-bottom: 1em;
	background: transparent;
	font-size: 1em;
	border: none;
	border-bottom: 2px solid var(--sandy-brown-60);
	color: var(--sandy-brown);
	height: fit-content;
	font-family: OpenSans;
	resize: none;
	overflow: hidden;
	max-height: 30%;
}

::placeholder {
	color: var(--sandy-brown-30);
	padding: 0;
	margin: 0;
}

#message {
	width: 98%;
}

#submit {
	width: 30%;
	margin-right: 20%;
	background-color: var(--eerie-black-30);
	border: none;
	border-radius: 5px;
	color: var(--sandy-brown-30);
	font-weight: bold;
	transition: color 0.2s, background-color 0.2s;
}

#submit:hover {
	color: var(--sandy-brown);
	background-color: var(--eerie-black-60);
}

#submit:active {
	box-shadow: inset 0px 5px 5px 5px rgba(0, 0, 0, 0.15);
}

.email {
	color: var(--sandy-brown-60);
	display: flex;
	align-items: center;
}

.email-link {
	color: var(--sandy-brown-60);
	text-decoration: underline;
	margin-left: 5px;
}

.email-link:hover {
	color: var(--sandy-brown);
	text-decoration: underline;
	margin-left: 5px;
}
