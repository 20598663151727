.header-div {
	border-color: var(--sandy-brown-60);
	border-style: dashed;
	border-width: 0 0 0.2em 0;
	width: 100%;
}

.header {
	color: var(--sandy-brown);
	font-size: 2.2em;
	font-weight: 500;
}

.SkillsElem-div {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 5px 20px 5px 20px;
}

.name {
	color: var(--sandy-brown);
	font-size: 1.4em;
	margin-right: 20px;
}
.circle-container {
	display: flex;
	height: 100%;
	align-items: center;
}

.empty-circle {
	width: 1.2em;
	height: 1.2em;
	margin: 0.2em;
	border-radius: 50%;
	border: solid 3px var(--sandy-brown);
}

.full-circle {
	width: 1.2em;
	height: 1.2em;
	margin: 0.2em;
	border-radius: 50%;
	background-color: var(--sandy-brown);
}

.half-circle {
	position: relative;
	width: 1.2em;
	height: 1.2em;
	margin: 0.2em;

	.half-circle-filling {
		position: absolute;
		width: 0.6em;
		height: 1.2em;
		border-radius: 1.2em 0 0 1.2em;
		background-color: var(--sandy-brown);
	}

	.half-circle-ring {
		position: absolute;
		width: 1.2em;
		height: 1.2em;
		border-radius: 50%;
		border: solid 3px var(--sandy-brown);
	}
}
