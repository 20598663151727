.proj-content {
	display: flex;
	flex-direction: row-reverse;
	height: 100%;
	flex-direction: column;
	overflow-y: auto;
}

.proj-elem {
	display: flex;
	background-color: var(--eerie-black-30);
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	align-items: center;

	.proj-elem-link {
		font-size: 0.8em;
		margin: 0.5em 0 0.5em 0;
		text-align: right;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.swiper {
		width: 100%;
		height: 100%;

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;

			/* Center slide text vertically */
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.proj-content > .proj-elem:last-child {
	margin: 0;
}

.proj-swiper {
	aspect-ratio: 4/3;
	border-radius: 5px;
	height: 16em;
}

.proj-elem-text {
	display: flex;
	flex-direction: column;
	color: var(--sandy-brown);
	padding: 0 0 0 0.75em;
}

.proj-elem-text > h2 {
	color: var();
	font-size: 2em;
	font-weight: 500;
}

.proj-elem-text > p {
	margin: 0;
	flex-grow: 1;
}

.proj-elem-text-tags {
	display: flex;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
}

.proj-elem-text-tags-tag {
	color: var(--sandy-brown);
	background-color: var(--eerie-black-60);
	margin: 5px;
	padding: 5px;
	border-radius: 5px;
}
