.Content {
	width: 100%;
	height: 100%;
	padding: 20px;
	border: solid 1px var(--sandy-brown);
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.view-content {
	flex-grow: 1;
}

.fade {
	height: 100%;
	display: flex;
	flex-direction: row-reverse;
}

/* The starting state when the component is being entered */
.fade-enter {
	transform: translateX(50px);
	opacity: 0;
}

/* The ending state after the component has been added */
.fade-enter-active {
	transform: translateX(0);
	opacity: 1;
	transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

/* The starting state when the component is being exited */
.fade-exit {
	transform: translateX(0);
	opacity: 1;
}

/* The ending state as the component is removed */
.fade-exit-active {
	transform: translateX(50px);
	opacity: 0;
	transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
