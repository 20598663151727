.App {
	width: 100vw;
	height: 100vh;
	padding: 50px;
	font-size: 1vw;
}

.cursor {
	width: 1.5em;
	height: 1.5em;
	background-color: var(--eerie-black-30);
	border-radius: 1em;
	position: absolute;
	z-index: 9999;
	border: 0.15em var(--sandy-brown-60) solid;
	pointer-events: none;
	transition: height 0.2s cubic-bezier(0.7, 0, 0.3, 1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.cursor.scroll-elem {
	height: 3em;
}

.cursor.hoveringClickable {
	background-color: var(--eerie-black);
}

.cursor.mousePressed {
	background-color: var(--eerie-black);
	border-color: var(--sandy-brown);
}

.inner-cursor {
	width: 0.5em;
	height: 0.5em;
	border-radius: 0.5em;
	opacity: 0;
	background-color: var(--sandy-brown-60);
	transition: opacity 0.2s ease-in-out;
}

.inner-cursor.hoveringClickable {
	opacity: 1;
}

.inner-cursor.mousePressed {
	background-color: var(--sandy-brown);
}

@keyframes scrollDown {
	0%,
	100% {
		transform: translateY(0);
		border-color: #f4a66680;
	}
	50% {
		transform: translateY(1em);
		border-color: #f4a666;
	}
}
@keyframes scrollUp {
	0%,
	100% {
		transform: translateY(0);
		border-color: #f4a66680;
	}
	50% {
		transform: translateY(-1em);
		border-color: #f4a666;
	}
}
