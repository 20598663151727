.home-div {
	height: 100%;
	min-width: 300px;
	display: flex;
	flex-direction: row-reverse;

	.swiper {
		height: 100%;
		object-fit: cover;
		align-content: center;
		aspect-ratio: 1/2;
		border-radius: 5px;
		box-shadow: 5px 5px 5px 0px var(--eerie-black-60);
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
