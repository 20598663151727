.Menu {
	height: 100%;
	box-sizing: border-box;
	color: var(--sandy-brown);
	padding-left: 20px;
	padding-right: 50px;
	display: flex;
	flex-direction: column;
	min-width: fit-content;

	.MenuList {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		overflow: hidden;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			font-size: 1.1em;
			padding: 0.4em 0.8em 0.4em 0;
			color: var(--sandy-brown-30);
			font-weight: bold;
			width: fit-content;
		}

		li > a {
			text-decoration: none;
			transition: color 0.2s;
			color: var(--sandy-brown-30);
		}

		li > a.active {
			color: var(--sandy-brown);
		}
	}

	h1 {
		font-size: 4.5em;
		margin: 0;
		font-weight: 350;
	}

	h2 {
		font-size: 1.5em;
		margin: 0;
		font-weight: 300;
	}

	.link-div {
		display: flex;
		align-items: center; /* Aligns items vertically center */
		text-decoration: none; /* Optional: Removes underline from links */
	}

	.lock-icon {
		height: 1.1em;
		width: 1.1em;
		margin-left: 0.3em;
	}
}
