.skills-div {
	height: 100%;
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.skills-body {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
